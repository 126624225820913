@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
.App{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Source Code Pro', monospace;
}

.App h1{
  font-size: 40px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
}

h1:before{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  content: '';
  animation: typing steps(14) 4s forwards;
}

h1::after{
  width: 1px;
  height: 90%;
  top: 0;
  bottom: 0;
  background-color: #000000;
  left: 0;
  position: absolute;
  content: '';
  animation: typing steps(14) 4s forwards,pulse infinite 1s 4.5s ease;

}



@keyframes typing {
  to{
    left: 100%;
  }
}
@keyframes pulse {
  from{opacity: 1;}
  to{opacity: 0;}
}