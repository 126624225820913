@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Source Code Pro', monospace;
}

.App h1{
  font-size: 40px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
}

h1:before{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  content: '';
  -webkit-animation: typing steps(14) 4s forwards;
          animation: typing steps(14) 4s forwards;
}

h1::after{
  width: 1px;
  height: 90%;
  top: 0;
  bottom: 0;
  background-color: #000000;
  left: 0;
  position: absolute;
  content: '';
  -webkit-animation: typing steps(14) 4s forwards,pulse infinite 1s 4.5s ease;
          animation: typing steps(14) 4s forwards,pulse infinite 1s 4.5s ease;

}



@-webkit-keyframes typing {
  to{
    left: 100%;
  }
}



@keyframes typing {
  to{
    left: 100%;
  }
}
@-webkit-keyframes pulse {
  from{opacity: 1;}
  to{opacity: 0;}
}
@keyframes pulse {
  from{opacity: 1;}
  to{opacity: 0;}
}
